import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; 
import { useNavigate } from 'react-router-dom';

function UserList() {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('aktif');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [allSelected, setAllSelected] = useState(false);
  const role = localStorage.getItem('role');
  const username = localStorage.getItem('username');

  
  const navigate = useNavigate();


  const handleLogout = () => {
      localStorage.removeItem('username');
      navigate('/');
  };

  // Sayfa açıldığında aktif kullanıcıları getir
  useEffect(() => {
    fetchUsers('aktif');
  }, []);

  // API'den kullanıcıları çek
  const fetchUsers = (userType) => {
    let apiUrl = '';
    if (userType === 'aktif') apiUrl = 'https://api.idekod.com.tr/aktif';
    else if (userType === 'bekleyen') apiUrl = 'https://api.idekod.com.tr/bekleyen';
    else if (userType === 'pasif') apiUrl = 'https://api.idekod.com.tr/pasif';

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Eğer dönen veri bir array değilse boş bir array olarak set et
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          setUsers([]);
        }
        setSelectedUsers([]);
        setAllSelected(false);
      })
      .catch((error) => console.error('Error:', error));
  };

  // Combobox'ta seçim yapıldığında filtreyi değiştir ve kullanıcıları tekrar getir
  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
    fetchUsers(selectedFilter);
  };

  // Checkbox ile kullanıcıları seç
  const handleUserSelect = (telno) => {
    if (selectedUsers.includes(telno)) {
      setSelectedUsers(selectedUsers.filter((userTelno) => userTelno !== telno));
    } else {
      setSelectedUsers([...selectedUsers, telno]);
    }
  };

  // Tüm kullanıcıları seç
  const handleSelectAll = () => {
    if (allSelected) {
      setSelectedUsers([]);
    } else {
      const allUserTelnos = users.map((user) => user.TELNO);
      setSelectedUsers(allUserTelnos);
    }
    setAllSelected(!allSelected);
  };

  // API'ye aktif et veya iptal et isteği gönder
  const handleStatusChange = (newState) => {
    fetch('https://api.idekod.com.tr/update-users', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ telnos: selectedUsers, state: newState }),
    })
      .then((response) => response.json())
      .then((data) => {
        // İşlem başarılı olursa kullanıcıları tekrar getir
        fetchUsers(filter);
      })
      .catch((error) => console.error('Error:', error));
  };

  return (
    <div className="p-6 space-y-8">
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
  
  <div className="bg-gray-600 p-4 text-black">
    
      <h1 className="text-xl font-semibold">Kullanıcı Listesi</h1>

      <select onChange={handleFilterChange } value={filter}  className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
        <option value="aktif">Aktif Kullanıcılar</option>
        <option value="bekleyen">Bekleyen Kullanıcılar</option>
        <option value="pasif">Pasif Kullanıcılar</option>
      </select>
    
      {filter === 'bekleyen' && (
      <div className="flex space-x-4">
      <button
        className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
        onClick={() => handleStatusChange(1)}
      >
        Seçilenleri Aktif Et
      </button>
      <button
        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        onClick={() => handleStatusChange(3)}
      >
        Seçilenleri İptal Et
      </button>
      <button
        className={`${
          allSelected ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-blue-500 hover:bg-blue-600'
        } text-white px-4 py-2 rounded`}
        onClick={handleSelectAll}
      >
        {allSelected ? 'Tümünü Seçme' : 'Tümünü Seç'}
      </button>
    </div>
    
      )}
</div>
      <table className="min-w-full table-auto">
        <thead>
          <tr className="bg-gray-200 text-gray-700">
            
            <th className="px-4 py-2 text-left">Adı</th>
            <th className="px-4 py-2 text-left">Telefon</th>
            <th className="px-4 py-2 text-left">Durum</th>
            {filter === 'bekleyen' && <th>Seç</th>}
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.TELNO} className={`${user % 2 === 0 ? 'bg-gray-50' : 'bg-white'} text-gray-700`}>
              
              <td>{user.RNAME}</td>
              <td>{user.TELNO}</td>
              <td>{user.STATE=== 0 ? 'Onay Bekliyor' : user.STATE == 1 ? 'Aktif Kullanıcı' : user.STATE == 2 ? 'Pasif Kullanıcı':'test'}</td>
              {filter === 'bekleyen' && (
                <td>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.TELNO)}
                    onChange={() => handleUserSelect(user.TELNO)}
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
}

export default UserList;
