import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Hakedis() {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('aktif');
  const [editingUser, setEditingUser] = useState(null);  
  const [editedHakedis, setEditedHakedis] = useState('');  
  const role = localStorage.getItem('role');
  const username = localStorage.getItem('username');
  const [excelFile, setExcelFile] = useState(null);
  const [transferYearFrom, setTransferYearFrom] = useState('previous'); // Aktarım başlangıç yılı
  const [transferYearTo, setTransferYearTo] = useState('current'); // Aktarım bitiş yılı

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('username');
    navigate('/');
  };

  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const nextYear = currentYear + 1;

  useEffect(() => {
    fetchUsers('aktif');
  }, []);

  const fetchUsers = (userType) => {
    let apiUrl = '';
    if (userType === 'aktif') apiUrl = 'https://api.idekod.com.tr/hakedis';
    else if (userType === 'bekleyen') apiUrl = 'https://api.idekod.com.tr/bekleyen';
    else if (userType === 'pasif') apiUrl = 'https://api.idekod.com.tr/pasif';

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setUsers(data);
        } else {
          setUsers([]);
        }
      })
      .catch((error) => console.error('Error:', error));
  };

  const handleFilterChange = (e) => {
    const selectedFilter = e.target.value;
    setFilter(selectedFilter);
    fetchUsers(selectedFilter);
  };

  const startEditing = (user) => {
    setEditingUser(user.PK);
    setEditedHakedis(user[`${currentYear}`]);
  };

  const confirmEdit = (user) => {
    const confirmed = window.confirm('Değişiklikleri kaydetmek istiyor musunuz?');
    if (confirmed) {
      fetch('https://api.idekod.com.tr/hakedisupdate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ PK: user.PK, year: currentYear, hakedis: editedHakedis }),
      })
      .then((response) => response.json())
      .then(() => {
        fetchUsers(filter);
        cancelEditing();
      })
      .catch((error) => console.error('Error:', error));
    }
  };

  const cancelEditing = () => {
    setEditingUser(null);
    setEditedHakedis('');
  };

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };

  const handleExcelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', excelFile);

    try {
      const response = await fetch('https://api.idekod.com.tr/api/users/excelhakedis', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Kullanıcılar başarıyla eklendi!');
      } else {
        alert('Excel dosyasından kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  const handleTransfer = () => {
    const fromYear = transferYearFrom === 'previous' ? previousYear : currentYear;
    const toYear = transferYearTo === 'current' ? currentYear : nextYear;

    users.forEach(user => {
      const currentHakedis = user[toYear] || 0; // Hakedis değerini al
      const additionalHakedis = user[fromYear] || 0; // Aktarılan değeri al
      const newHakedis = currentHakedis + additionalHakedis; // Toplam

      // Aktarım zamanı
      const transferTime = new Date().toISOString();

      // API'ye gönderim
      fetch('https://api.idekod.com.tr/hakedistransfer', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          PK: user.PK,
          year: toYear,
          hakedis: newHakedis,
          aktarimKullanici: username, // LocalStorage'den alınan kullanıcı adı
           // Aktarım zamanı
        }),
      })
      .then(response => response.json())
      .then(data => {
        console.log('Aktarım başarılı:', data);
        fetchUsers(filter); // Güncel veriyi çek
      })
      .catch(error => console.error('Aktarım hatası:', error));
    });
  };

  return (
    <div className="p-6 space-y-8">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
        <div className="bg-gray-600 p-4 text-black">
          <h1 className="text-xl font-semibold text-center">Kullanıcı Listesi</h1>
          <h2 className="text-lg font-bold mt-8 mb-4">Excelden Aktar</h2>
          <form onSubmit={handleExcelSubmit}>
            <input
              type="file"
              accept=".xlsx, .xls"
              onChange={handleFileChange}
              required
              className="mb-4 w-full py-2 border border-gray-300 rounded-lg"
            />
            <button
              type="submit"
              className="w-full bg-customColortext-white font-semibold py-2 rounded-lg hover:bg-green-600 transition duration-200"
            >
              Excelden Aktar
            </button>
            <button 
  onClick={() => window.location.href = 'http://pdks.idekod.com.tr/indirmeler/hakedis.xlsx'}
  className="w-full bg-customColor2 text-white font-semibold py-2 rounded-lg hover:bg-green-600 transition duration-200"
>
  Örnek Excel Dosyasını İndir  
</button>

          </form>
        </div>

        <div className="p-4">
          <h2 className="text-lg font-bold mt-8 mb-4">Veri Aktar</h2>
          <div className="flex items-center space-x-4">
            <span>{previousYear}</span>
            <span>&#8594;</span> {/* Sağ yön işareti */}
            <span>{currentYear}</span>
            <button
              onClick={handleTransfer}
              className="bg-customColor text-white font-semibold py-2 px-4 rounded-lg hover:bg-customColor2 transition duration-200"
            >
              Kalanları Aktar
            </button>
          </div>
        </div>

        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              <th className="px-4 py-2 text-left">Adı</th>
              <th className="px-4 py-2 text-left">Telefon</th>
              <th className="px-4 py-2 text-left">Durum</th>
              <th className="px-4 py-2 text-left">Başlangıç Tarihi</th>
              <th className="px-4 py-2 text-left">Hak Ediş ({previousYear})</th>
              <th className="px-4 py-2 text-left">Hak Ediş ({currentYear})</th>
              <th className="px-4 py-2 text-left">Hak Ediş ({nextYear})</th>
              <th className="px-4 py-2 text-left">İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={user.PK} className={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} text-gray-700`}>
                <td>{user.RNAME}</td>
                <td>{user.telno}</td>
                <td>{user.STATE}</td>
                <td>{new Date(user.girist).toLocaleDateString('tr-TR')}</td>
                <td>{user[previousYear]}</td>
                <td>
                  {editingUser === user.PK ? (
                    <input
                      type="number"
                      value={editedHakedis}
                      onChange={(e) => setEditedHakedis(e.target.value)}
                      className="border border-gray-300 rounded-lg py-1 px-2"
                    />
                  ) : (
                    user[currentYear]
                  )}
                </td>
                
                <td>{user[nextYear]}</td>
                <td className="flex space-x-2">
                  {editingUser === user.PK ? (
                    <>
                      <button
                        onClick={() => confirmEdit(user)}
                        className="bg-green-500 text-white font-semibold py-1 px-2 rounded-lg hover:bg-green-600 transition duration-200"
                      >
                        Kaydet
                      </button>
                      <button
                        onClick={cancelEditing}
                        className="bg-red-500 text-white font-semibold py-1 px-2 rounded-lg hover:bg-red-600 transition duration-200"
                      >
                        İptal
                      </button>
                    </>
                  ) : (
                    <button
                      onClick={() => startEditing(user)}
                      className="bg-customColor text-white font-semibold py-1 px-2 rounded-lg hover:bg-customColor2 transition duration-200"
                    >
                      Düzenle
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Hakedis;
