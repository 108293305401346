import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'; // Heroicons v2 ikonları

const Sidebar = ({ isOpen, toggleSidebar}) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [menuOpen, setmenuOpen] = useState(false);
  const [rname, setRname] = useState('');
  const [kurumAd, setKurumAd] = useState('');  // Kurum adı
  const [logo, setLogo] = useState('');        // Logo resmi (base64)
  const navigate = useNavigate();
  
  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    // Gerekli yönlendirmeleri yap
    window.location.href = '/'; // Giriş sayfasına yönlendirin
};

  const username = localStorage.getItem('username');
  const company = localStorage.getItem('company'); // localStorage'dan 'company' bilgisini alıyoruz
  const role = localStorage.getItem('role'); // Kullanıcının yetkisini localStorage'dan alıyoruz

  useEffect(() => {
    // API'den RNAME bilgisini alıyoruz
    fetch(`https://api.idekod.com.tr/api/personel/${username}`)
      .then((response) => response.json())
      .then((data) => {
        setRname(data.RNAME);
      })
      .catch((error) => {
        console.error('RNAME alınırken hata oluştu:', error);
      });
  }, [username]);

  useEffect(() => {
    // Kurum bilgisi için API çağrısı
    fetch(`https://api.idekod.com.tr/api/kurumbilgi/${company}`)
      .then((response) => response.json())
      .then((data) => {
        setKurumAd(data.kurumad);   // Kurum adını alıyoruz
        setLogo(data.logourl);         // Logo'yu base64 formatında alıyoruz
      })
      .catch((error) => {
        console.error('Kurum bilgisi alınırken hata oluştu:', error);
      });
  }, [company]);

  return (
    
    <div className={`${isOpen ? 'w-64' : 'w-16'} h-screen bg-customColor2 text-white transition-all  duration-300 relative`}>
       {/* Header */}
      
<header className="bg-customColor2 text-white fixed top-0 left-0  w-full z-10">
  <div className="container mx-auto flex justify-between items-center">
    <div className="flex items-center space-x-4">
      {logo && <img src={`${logo}`} alt="Logo" className="w-11 h-11 mr-1" />}
      <h1 className="text-lg font-semibold">{kurumAd}</h1>
    </div>
    
    <div className="ml-auto flex items-center space-x-4">
      <span className="text-sm font-medium">{rname}</span>
      <button
        onClick={handleLogout}
        className="text-white hover:text-gray-400 focus:outline-none"
        title="Çıkış Yap"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  </div>
</header>

    

    {/* Hamburger Menüsü veya Kapatma Butonu */}
    <div className="absolute top-4 right-4 cursor-pointer" onClick={toggleSidebar}>
      {isOpen ? (
        <XMarkIcon className="h-6 w-6 text-white" />
      ) : (
        <Bars3Icon className="h-6 w-6 text-white" />
      )}
    </div>

    {/* Kullanıcı Bilgisi 
    <div className="p-4">
      {isOpen && (
        <>
          <div className="flex items-center mb-4">
            {logo && <img src={`${logo}`} alt="Logo" className="w-13 h-10 rounded-full mr-2" />}
            <h2 className="text-lg font-semibold">{kurumAd}</h2>
          </div>
      
         
        </>
      )}
    </div>
*/}
<div className="p-4"></div>
    <nav>
      <ul className="space-y-4 px-4 py-5 mu-5">
        <li>
          <Link to="/home" className="flex items-center text-gray-300 hover:text-white transition">
            
            <span className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`}>ANASAYFA</span>
          </Link>
        </li>

        {role === '1' && (
          <li>
            <Link
              className="flex items-center text-gray-300 hover:text-white transition"
              onClick={() => setSubmenuOpen(!submenuOpen)}
            >
              
              <span className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`}>PERSONEL</span>
            </Link>
            {submenuOpen && isOpen && (
              <ul className="ml-4 space-y-2 mu-5">
                <li>
                  <Link to="/pjoin" className="text-gray-300 hover:text-white transition flex items-center">
                   
                    <span className="material-icons">PERSONEL KAYIT</span>
                  </Link>
                </li>
                <li>
                  <Link to="/pedit" className="text-gray-300 hover:text-white transition flex items-center">
                  
                    <span className="material-icons" >PERSONEL DÜZENLE</span>
                  </Link>
                </li>
                <li>
                  <Link to="/person" className="text-gray-300 hover:text-white transition flex items-center">
                   
                    <span className="material-icons">PERSONEL TAKİP</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        )}






{role === '1' && (
          <li>
            <Link
              className="flex items-center text-gray-300 hover:text-white transition"
              onClick={() => setmenuOpen(!menuOpen)}
            >
              
              <span className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`}>İZİN TALEP / ONAY TAKİP</span>
            </Link>
            {menuOpen && isOpen && (
              <ul className="ml-4 space-y-2 mu-5">
                <li>
                  <Link to="/permission" className="text-gray-300 hover:text-white transition flex items-center">
                   
                    <span className="material-icons">İZİN KAYIT</span>
                  </Link>
                </li>
                <li>
                  <Link to="/editpermission" className="text-gray-300 hover:text-white transition flex items-center">
                  
                    <span className="material-icons" >İZİN ONAY</span>
                  </Link>
                </li>
              </ul>
            )}
          </li>
        )}




        
        

        {role === '1' && (
          <li>
            <Link to="/company" className="flex items-center text-gray-300 hover:text-white transition">
            
              <span className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`}>KURUM BİLGİLERİ</span>
            </Link>
          </li>
        )}

        {role === '1' && (
        <li>
          <Link to="/hakedis" className="flex items-center text-gray-300 hover:text-white transition">
           
            <span className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`}>HAKEDİŞ</span>
          </Link>
        </li>
        )}
        <li>
          <Link to="/help" className="flex items-center text-gray-300 hover:text-white transition">
            
            <span className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`}>İLETİŞİM</span>
          </Link>
        </li>

        <li>
          <Link  className="flex items-center text-gray-300 hover:text-white transition">
            
          <span 
      className={`material-icons ${isOpen ? 'block' : 'hidden'} ml-2`} 
      onClick={handleLogout} // Butona tıklandığında logout işlemi
      style={{ cursor: 'pointer' }} // Fare imlecini göster
    >
      ÇIKIŞ YAP
    </span>
          </Link>
        </li>
      </ul>
      
    </nav>


  </div>
  );
};

export default Sidebar;
