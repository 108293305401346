import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes,Link, useNavigate, Navigate, useLocation } from 'react-router-dom';
import loginImage from './LoginImage.png';
import errorImage from './error.png'; // Hata resmi
import Person from './person';
import Sidebar from './Sidebar'; 
import UserForm from './UserForm';
import IzinSayfasi from './IzinSayfasi';
import UserManagement from './UserManagement';
import Login from './login';
import HomePage from './homepage';
import Kurumbilgi from './Kurumbilgi';
import Hakedis from './hakedis';
import Home from './home';
import IzinOnay from './IzinOnay';

// NotFoundPage bileşeni: 404 hata sayfası
  const NotFoundPage = () => {
  const navigate = useNavigate();
 

  const handleLogout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('role');
    localStorage.removeItem('company');
    // Gerekli yönlendirmeleri yap
    window.location.href = '/'; // Giriş sayfasına yönlendirin
};


  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <img src={errorImage} alt="Error" className="w-1/2 mb-4" />
      <p className="text-lg">Bir hata oluştu. Lütfen tekrar giriş yapınız.</p>
      <button
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700"
        onClick={() => navigate('/login')}
      >
        Giriş Sayfasına Dön
      </button>
    </div>
  );
};

// MainContent bileşeni: Ana içerik yapısı
const MainContent = ({handleLogout}) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true); // Sidebar açık/kapalı durumu
  const location = useLocation(); // Aktif rotayı almak için

  // Sidebar ve header'ın gizlenmesi gereken rotalar
  const hideSidebarAndHeaderRoutes = ['/', '/login'];
  const isSidebarAndHeaderHidden = hideSidebarAndHeaderRoutes.includes(location.pathname); 

  return (
    <div className="flex flex-col h-screen  w-full">
 
      {/* Main Content */}
      <div className={`flex flex-grow ${!isSidebarAndHeaderHidden ? 'pt-16' : ''}`}> 
        {/* Sidebar: Eğer sidebar ve header gizlenmemişse */}
        {!isSidebarAndHeaderHidden && (
          <div className={`transition-transform duration-300 ease-in-out ${isSidebarOpen ? 'w-64' : 'w-16'} bg-gray-800 h-screen fixed top-16 left-0`}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={() => setSidebarOpen(!isSidebarOpen)} />
          </div>
        )}

        {/* Content Area */}
        <div className={`flex-grow  ${isSidebarAndHeaderHidden ? 'w-full' : 'ml-0'} ${!isSidebarAndHeaderHidden ? (isSidebarOpen ? 'ml-64' : 'ml-16') : ''} bg-gray-100`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/company" element={<Kurumbilgi />} />
            <Route path="/pjoin" element={<UserForm />} />
            <Route path="/pedit" element={<UserManagement />} />
            <Route path="/person" element={<Person />} />
            <Route path="/permission" element={<IzinSayfasi />} />
            <Route path="/editpermission" element={<IzinOnay/>} />
            <Route path="/hakedis" element={<Hakedis />} />
            <Route path="/help" element={<div>İletişim Sayfası</div>} />
            <Route path="/404" element={<NotFoundPage />} />
            <Route path="*" element={<Navigate to="/404" />} />
          </Routes>
        </div>
      </div>

      {/* Footer */}
      <footer className="bg-customColor2 text-white p-2 text-center w-full fixed bottom-0">
        <div className="container mx-auto">
          <p>&copy; İDEKOD | 2024 Personel Takip Sistemi |  Tüm Hakları Saklıdır.</p>
        </div>
      </footer>
    </div>
  );
};

// App bileşeni: Ana uygulama yapısı
const App = () => {
  return (
    <Router>
      <MainContent />
    </Router>
  );
};

export default App;
