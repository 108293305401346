import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserForm = () => {
  const [realname, setRealname] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [status, setStatus] = useState('');
  const [company, setCompany] = useState('');
  const [eposta, setEposta] = useState('');
  const [excelFile, setExcelFile] = useState(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const navigate = useNavigate();


  const handleLogout = () => {
      localStorage.removeItem('username');
      navigate('/');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userData = {
      realname,
      username,
      password,
      phone,
      company,
      status,
      eposta
    };

    try {
      const response = await fetch('http://localhost:5000/api/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(userData)
      });

      if (response.ok) {
        toast.success('Kullanıcı başarıyla eklendi!', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: { 
            backgroundColor: '#4caf50', 
            color: 'white', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            minWidth: '300px' 
          },
          // Kapama butonunun sağda görünmesi için
          closeButton: <div style={{ color: 'white', marginRight: '8px' }}>✖</div>
        });
                        // Formu temizle
                        setRealname('');
                        setUsername('');
                        setPassword('');
                        setPhone('');
                        setCompany('');
                        setStatus('');
                        setEposta('');
      } 
      else {
        toast.error('Kullanıcı ekleme başarısız oldu.', {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          style: { 
            backgroundColor: '#ff4d4d', 
            color: 'white', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between', 
            minWidth: '300px' 
          },
          // Kapama butonunun sağda görünmesi için
          closeButton: <div style={{ color: 'white', marginRight: '8px' }}>✖</div>
        });

      }
    } 
    catch (error) {
      console.error('Hata:', error);
      toast.error('Bir hata oluştu. Lütfen tekrar deneyin.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: { 
          backgroundColor: '#ff4d4d', 
          color: 'white', 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between', 
          minWidth: '300px' // Genişliği artırmak için
        },
        // Kapama butonunun sağda görünmesi için
        closeButton: <div style={{ color: 'white', marginRight: '8px' }}>✖</div>
      });
    }
  };

  const handleFileChange = (e) => {
    setExcelFile(e.target.files[0]);
  };


  const handleExcelSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('file', excelFile);

    try {
      const response = await fetch('https://api.idekod.com.tr/api/users/excel', {
        method: 'POST',
        body: formData
      });

      if (response.ok) {
        alert('Kullanıcılar başarıyla eklendi!');
      } else {
        alert('Excel dosyasından kullanıcı ekleme başarısız oldu.');
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };

  return (
    <div className="p-6 space-y-8">
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
  
  <div className="bg-gray-600 p-4 text-white">
    
<h1 className="text-xl font-semibold text-center">Kullanıcı Ekle</h1>
                
                

  </div>
      <form onSubmit={handleSubmit} className="min-w-full table-auto mb-2 py-2 px-4"> 
       
          <div>
            <label className="py-2 px-4 border-b text-left">Kullanıcı Ad Soyad:</label>
            <input
              type="text"
              value={realname}
              onChange={(e) => setRealname(e.target.value)}
              required
              className="focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="py-2 px-4 border-b text-left">Kullanıcı ID:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              className=" focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="py-2 px-4 border-b text-left">Şifre:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="py-2 px-4 border-b text-left">Telefon:</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              className="focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="py-2 px-4 border-b text-left">Durum:</label>
            <input
              type="text"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              required
              className="focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="py-2 px-4 border-b text-left">Şirket Kısa Kod:</label>
            <input
              type="text"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
              className="focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="py-2 px-4 border-b text-left">E-posta:</label>
            <input
              type="email"
              value={eposta}
              onChange={(e) => setEposta(e.target.value)}
              required
              className=" focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
      
        <button
          type="submit"
          className="w-full bg-customControl text-white font-semibold py-2 rounded-lg hover:bg-customColor2 transition duration-200"
        >
          Kaydet
        </button>
      </form>
      </div>
    </div>
  );
};

export default UserForm;
