import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import { useNavigate } from 'react-router-dom';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
};

const IzinOnay = () => {
    const [personeller, setPersoneller] = useState([]);
    const [izinler, setIzinler] = useState([]);
    const [filteredIzinler, setFilteredIzinler] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const [selectedPersonel, setSelectedPersonel] = useState('');
    const [izinTur, setIzinTur] = useState('Yıllık İzin');
    const [basTar, setBasTar] = useState('');
    const [bitTar, setBitTar] = useState('');
    const [basSaat, setBasSaat] = useState('');
    const [bitSaat, setBitSaat] = useState('');
    const [aciklama, setAciklama] = useState('');
    const role = localStorage.getItem('role');
    const username = localStorage.getItem('username');

    const navigate = useNavigate();

    useEffect(() => {
        fetch(`https://api.idekod.com.tr/api/personel?role=${role}&username=${username}`)
            .then(response => response.json())
            .then(data => {
                setPersoneller(data);
                if (role !== '1') {
                    setSelectedPersonel(data[0]?.TELNO);
                }
            });

        fetchIzinler();
    }, [role, username]);

    useEffect(() => {
        const filtered = izinler.filter(izin =>
            izin.RNAME.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredIzinler(filtered);
    }, [searchTerm, izinler]);

    const fetchIzinler = () => {
        fetch(`https://api.idekod.com.tr/api/izinler?role=${role}&username=${username}`)
            .then(response => response.json())
            .then(data => setIzinler(Array.isArray(data) ? data : []))
            .catch(error => console.error('İzinler alınırken hata oluştu:', error));
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);

        const sorted = [...filteredIzinler].sort((a, b) => {
            if (field === 'RNAME') {
                return order === 'asc' ? a.RNAME.localeCompare(b.RNAME) : b.RNAME.localeCompare(a.RNAME);
            } else if (field === 'BASTAR') {
                return order === 'asc'
                    ? new Date(a.BASTAR) - new Date(b.BASTAR)
                    : new Date(b.BASTAR) - new Date(a.BASTAR);
            }
            return 0;
        });
        setFilteredIzinler(sorted);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            TELNO: selectedPersonel,
            IZINTUR: izinTur,
            BASTAR: basTar,
            BITTAR: bitTar,
            BASSAAT: izinTur === 'Saatlik İzin' ? basSaat : null,
            BITSAAT: izinTur === 'Saatlik İzin' ? bitSaat : null,
            ACIKLAMA: aciklama
        };
        fetch('https://api.idekod.com.tr/api/izin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                fetchIzinler();
            });
    };

    const handleOnayla = (telno) => {
        fetch('https://api.idekod.com.tr/api/izin/onayla', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ telno })
        })
            .then(response => response.json())
            .then(data => {
                alert(data.message);
                fetchIzinler();
            })
            .catch(error => console.error('Fetch error:', error));
    };

    const handleIptal = (telno, PK) => {
        const IPTAL_ACK = prompt('İptal nedenini girin:');
        if (IPTAL_ACK) {
            fetch('https://api.idekod.com.tr/api/izin/iptal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ telno, PK, IPTAL_ACK })
            })
                .then(response => response.json())
                .then(data => {
                    alert(data.message);
                    fetchIzinler();
                })
                .catch(error => console.error('Fetch error:', error));
        }
    };

    return (
        <div className="p-6 space-y-8">
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
      
         <div className="bg-gray-600 p-4 text-white">
                    <h1 className="text-xl font-semibold text-center">İzin Onay Formu</h1>
                    <input
                        type="text"
                        placeholder="Ada göre ara..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="input-date border text-black rounded-md px-3 py-2 mb-4 w-full max-w-xs"
                    />
                </div>
                <table className="min-w-full table-auto">
                    <thead>
                        <tr className="bg-gray-200 text-gray-700">
                            <th className="py-2 px-4 border-b text-left cursor-pointer" onClick={() => handleSort('RNAME')}>Adı Soyadı</th>
                            <th className="py-2 px-4 border-b text-left">İzin Türü</th>
                            <th className="py-2 px-4 border-b text-left cursor-pointer" onClick={() => handleSort('BASTAR')}>Başlangıç Tarihi</th>
                            <th className="py-2 px-4 border-b text-left">Bitiş Tarihi</th>
                            <th className="py-2 px-4 border-b text-left">Açıklama</th>
                            <th className="py-2 px-4 border-b text-left">Durum</th>
                            <th className="py-2 px-4 border-b text-left">İptal Nedeni</th>
                            {role === '1' && <th className="py-2 px-4 border-b text-left">İşlemler</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredIzinler.map(izin => (
                            <tr key={izin.PK} className="hover:bg-gray-100">
                                <td className="py-2 px-4 border-b">{izin.RNAME}</td>
                                <td className="py-2 px-4 border-b">{izin.IZINTUR}</td>
                                <td className="py-2 px-4 border-b">{formatDate(izin.BASTAR)}</td>
                                <td className="py-2 px-4 border-b">{formatDate(izin.BITTAR)}</td>
                                <td className="py-2 px-4 border-b">{izin.ACIKLAMA}</td>
                                <td className="py-2 px-4 border-b">{izin.ONAY === 0 ? 'Bekliyor' : izin.ONAY === 1 ? 'Onaylandı' : 'İptal Edildi'}</td>
                                <td className="py-2 px-4 border-b">{izin.IPTAL_ACK}</td>
                                {role === '1' && (
                                    <td className="border px-4 py-2 flex justify-between">
                                        <button onClick={() => handleOnayla(izin.TELNO)} className="text-green-600 hover:underline bg-customColor2 hover:bg-customColor">Onayla</button>
                                        <button onClick={() => handleIptal(izin.TELNO)} className="text-red-600 hover:underline ml-2 bg-customColor2 hover:bg-customColor">İptal</button>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default IzinOnay;
