import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 md:p-12 max-w-lg text-center">
                <h1 className="text-4xl font-semibold text-gray-800 mb-6">
                    İDEKOD Personel Takip Sistemine Hoş Geldiniz
                </h1>
                <p className="text-gray-600 mb-4">
                    Sisteme giriş yaparak personel takibinizi kolayca yönetin.
                </p>
                <Link 
                    to="/login"
                    className="inline-block mt-6 px-6 py-3 bg-blue-600 text-white font-medium text-lg rounded-lg shadow-md hover:bg-blue-700 transition duration-300"
                >
                    Giriş Yapmak İçin Tıklayınız
                </Link>
            </div>
        </div>
    );
};

export default Home;
