import React, { useState, useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from 'react-router-dom';
//import './App.css';
import loginImage from './LoginImage2.png';
import errorImage from './error.png'; // Hata resmi ekleyin
import Person from './person';
import Sidebar from './Sidebar'; 
import UserForm from './UserForm';
import IzinSayfasi from './IzinSayfasi';
import UserManagement from './UserManagement';
import Login from './login';

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  
  
  
  const HomePage = () => {
    const [personelData, setPersonelData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Bugünün tarihi varsayılan olarak
    const navigate = useNavigate();
  
  
    const handleLogout = () => {
      console.log('Çıkış yapılıyor...'); 
      localStorage.removeItem('username');
      localStorage.removeItem('role');
      navigate('/');
    };
  
  
    useEffect(() => {
      const fetchPersonelData = async () => {
        try {
          const response = await fetch(`https://api.idekod.com.tr/login/person?date=${selectedDate}`);
          
          if (!response.ok) {
            const errorData = await response.text();
            throw new Error(`Network response was not ok: ${response.status} ${response.statusText} - ${errorData}`);
          }
  
          const data = await response.json();
          console.log('Fetched Data:', data);
          setPersonelData(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      fetchPersonelData();
    }, [selectedDate]);
  
    const handleDateChange = (e) => {
      setSelectedDate(e.target.value);
    };
  
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
  
    return (
      <div className="p-6 space-y-8">
      <div className="bg-white shadow-md rounded-lg overflow-hidden">
    
       <div className="bg-gray-600 p-4 text-white">
      
       <h1 className="text-xl font-semibold text-center">Personel Giriş  /  Çıkış Bilgileri</h1>
                    <label htmlFor="date-picker" className="block text-sm  text-white font-medium text-gray-700 mb-2">
                             Tarih Seç:
                    </label>
                    <input
                        className="input-date border text-black rounded-md px-3 py-2 mb-4 w-full max-w-xs"
                        type="date"
                        id="date-picker"
                        value={selectedDate}
                        onChange={handleDateChange}
                        max={new Date().toISOString().split('T')[0]}
                    />
  
  
    </div>
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-200 text-gray-700">
              
                      <th className="py-2 px-4 border-b text-left">AD SOYAD</th>
                                    <th className="py-2 px-4 border-b text-left">TARİH</th>
                                    <th className="py-2 px-4 border-b text-left">GİRİŞ SAATİ</th>
                                    <th className="py-2 px-4 border-b text-left">ÇIKIŞ SAATİ</th>
                                    <th className="py-2 px-4 border-b text-left">DURUM</th>
            </tr>
          </thead>
                                   <tbody>
                                {personelData.length > 0 ? (
                                    personelData.map((personel) => {
                                        const durumClass = personel.DURUM === 2 ? 'text-red-500' : 'text-green-500';
                                        const durumText = personel.DURUM === 2 ? 'Çıkış Yaptı' : 'Giriş Yaptı';
  
                                        return (
                                            <tr key={personel.id} className="hover:bg-gray-100">
                                                <td className="py-2 px-4 border-b">{personel.RNAME}</td>
                                                <td className="py-2 px-4 border-b">{formatDate(personel.DATEG)}</td>
                                                <td className="py-2 px-4 border-b">{personel.TIMEG}</td>
                                                <td className="py-2 px-4 border-b">{personel.TIMEC}</td>
                                                <td className={`py-2 px-4 border-b ${durumClass}`}>{durumText}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="text-center py-4">
                                            Bu tarihte kaydınız yoktur.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
        </table>
        </div>
      </div>
  );
  };

  export default HomePage;